
import { Link } from "react-router-dom";
export default function Header(){

    return(
        <>
    
        <Block1 />
        <Block2 />
        </>

    );

}
function Preload(){
    return(

        <>

    <div id="preloader" className="preloader">
        <div className="animation-preloader">
            <div className="">
            <center><img src="assets/img/home-2/about-medi.png" alt="logo" /></center>       
            </div>
                <div className="txt-loading">
                    

                    <span data-text-preloader="HELVETICA" className="letters-loading">
                        HELVETICA
                    </span>
                </div>
  
        </div>
        <div className="loader">
            <div className="row">
                <div className="col-3 loader-section section-left">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-left">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                    <div className="bg"></div>
                </div>
                <div className="col-3 loader-section section-right">
                    <div className="bg"></div>
                </div>
            </div>
        </div>
    </div>
    
        
        </>
    );
}

function Block1(){
    return(
<>
        <header className="header header-1  header-3">
        <div className="top-header d-none d-xl-block" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-8">
                        <div className="header-cta">
                            <ul>
                                <li><a><i className="fal fa-clock"></i> Lundi – Vendredi : 8.00 – 17.00</a></li>
                                
                                <li><a href="mailto:contact@helveticaressources.com"><i className="icon-email"></i>contact@helveticaressources.com</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="header-right-socail d-flex justify-content-end align-items-center">
                            
                            <div className="social-profile">
                                <ul>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="main-header-wraper" >
            
            <div className="container" >
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="header-logo">
                                <div className="logo">
                                    
                                 <Link to={"/"}>  <a href="">
                                    <img src="assets/img/logo/g.png" alt="logo" />
                                       <span > HELVETICA RESSOURCES</span> 
                                    </a>
                                </Link> 
                                </div>
                            </div>

                            <div className="header-menu d-none d-xl-block">
                                <div className="main-menu">
                                    <ul>
                                        
                                    <li><a href=""><Link to={"/apropos"}>À propôs</Link> </a></li>
                                    <li><a href="#solution"> Solution populaire</a></li>
                                    <li><a href="#services">Nos prestations</a></li>
                                    <li><a href="#contact">Contact</a>

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="header-right d-flex align-items-center">

                                <div className="horizontal-bar d-none d-md-block"></div>

                                <a href="tel:+1235568824" className="header-contact d-none d-md-flex align-items-center">
                                    <div className="icon color-primary">
                                        <i className="icon-call"></i>
                                        
                                    </div>
                                    <div className="text">
                                       
                                        <h5 className="fw-500">+243854106765</h5>
                                    </div>
                                </a>

                                <div className="mobile-nav-bar d-block ml-3 ml-sm-5 d-xl-none">
                                    <div className="mobile-nav-wrap">                    
                                        <div id="hamburger">
                                            <i className="fal fa-bars"></i>
                                        </div>                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </header>
    <Block2 />

</>


    );
}

function Block2(){

    return(
        <div class="mobile-nav">
        <button type="button" class="close-nav">
            <i class="fal fa-times-circle"></i>
        </button>

        <nav class="sidebar-nav">
            <div class="navigation">
                <div class="consulter-mobile-nav"> 
                <ul>
                                        
                                        <li><a href="#apropos"> À propôs</a></li>
                                        <li><a href="#solution"> Solution populaire</a></li>
                                        <li><a href="#services">Nos prestations</a></li>
                                        <li><a href="#contact">Contact</a>
    
                                            </li>
                                        </ul>


              
                </div>

                <div class="sidebar-nav__bottom mt-20">
                    <div class="sidebar-nav__bottom-contact-infos mb-20">
                        <h6 class="color-black mb-5"></h6>
 
                        <ul>
                            <li><a><i class="fal fa-clock"></i> Lundi – Vendredi : 8.00 – 17.00</a></li>
                            <li><a href="mailto:contact@secysoft.com"><i class="icon-email"></i>contact@helveticaressources.com</a></li>
                            <li>
                                <a class="header-contact d-flex align-items-center">
                                    <div class="icon">
                                        <i class="icon-call"></i>
                                        
                                    </div>
                                    <div class="text">
                                       
                                        <h5 class="fw-500">+237 694739887</h5>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-nav__bottom-social">
                       
 
                        <ul>
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div> 
                </div>
            </div>
        </nav>
    </div>
    );
}