export default function Contact(){

    return(
        <>
        <Block1 />
       
        </>

    );

}

function Block1(){
    return(
<>
<section className="can-help can-help-home-3 bg-dark_white pb-xs-80 pb-sm-100 pb-md-100  overflow-hidden" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-xl-7">
                    <div className="can-help__content  mb-sm-40 mb-xs-40 mb-md-45 mb-lg-50">
                        <h2 className="title color-d_black mb-sm-15 mb-xs-10 mb-20">Prendre un Rendez-vous avec notre équipe</h2>

                        <div className="description font-la mb-md-25 mb-sm-25 mb-xs-20 mb-lg-30 mb-50">
                            <p>Nous sommes impatients de vous rencontrer chez Helvetica Ressources pour discuter de votre projet. Notre équipe expérimentée sera entièrement disponible pour répondre à vos besoins. À très bientôt</p>
                        </div>
                        <div className="can-help__content-btn-group d-flex flex-column flex-sm-row">
                            <a href="tel:+1235568824" className="theme-btn d-flex flex-column flex-md-row align-items-md-center">
                                <div className="icon">
                                    <i className="icon-call"></i>
                                   
                                </div>
                                <div className="text">
                                    <span className="font-la mb-10 d-block fw-500 color-d_black">Téléphone</span>
                                    <h5 className="fw-500 color-d_black">+243854106765</h5>
                                  
                                    <i className="icon-email-1"></i>

                                    <h6>Avenue du port Kinshasa Gombr</h6>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                   
          </div>
                                </div>
                                <br/>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d382.5123753633463!2d15.308841395774923!3d-4.300336061905316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sAvenue%20du%20port%20Kinshasa%20Gombe!5e0!3m2!1sen!2scm!4v1717889137829!5m2!1sen!2scm" width="400" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            </a>

 
                        </div>
                    </div>
                </div>

                <div className="col-xl-5">
                    <div className="contact-form pt-md-30 pt-sm-25 pt-xs-20 pb-md-40 pb-sm-35 pb-xs-30 pt-xl-30 pb-xl-50 pt-45 pr-xl-50 pl-md-40 pl-sm-30 pl-xs-25 pr-md-40 pr-sm-30 pr-xs-25 pl-xl-50 pr-85 pb-60 pl-85">
                        <div className="contact-form__header mb-sm-35 mb-xs-30 mb-40">
                            <h6 className="sub-title fw-500 color-yellow text-uppercase mb-15"><img src="assets/img/team-details/badge-line.svg" className="img-fluid mr-10" alt=""/>Nous contactez</h6>
                            <h3 className="title color-d_black"></h3>
                        </div>

                        <form action="mail.php" method="POST">
                            <div className="single-personal-info">
                                <input type="text" name="name" id="name" placeholder="Votre Noms"/>                                         
                            </div>                        
                            <div className="single-personal-info">
                                <input type="email" name="email" id="email" placeholder="Votre email"/>                                         
                            </div> 
                            <div className="single-personal-info">
                                <input type="text" name="subject" placeholder="Sujet" />                                         
                            </div>                                                 
                            <div className="single-personal-info">
                                <textarea name="message" placeholder="Votre message"></textarea>                                        
                            </div>                              
                            
                            <button type="submit" className="theme-btn btn-sm">Envoyer <i className="far fa-chevron-double-right"></i></button>                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
    </section>

</>

    );
}