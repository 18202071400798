import { Link } from "react-router-dom";


import Header from './Header2';
import Footer from './Footer';
import Services from './Services';
import Contact from "./Contact";




export default function Finance(){

    return(
        <>

        <Header/>
        <Block1 />
        <Contact/>
        <Footer/>
       
        </>

    );

}

function Block1(){
    return(
<>

<section className="services-details pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-115 overflow-hidden">
        <div className="container">
            <div className="row" data-sticky_parent>
                <div className="col-xl-8" data-sticky_column>
                    <div className="media mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                        <img src="assets/img/about/fin.jpg" alt="" />
                    </div>

                    <div className="services-details__content">
                        <h2>Market intelligence</h2>

                        <p>Notre service combine une expertise financière solide avec une protection complète grâce à nos solutions d'assurance sur mesure. En travaillant avec notre équipe, vous bénéficierez d'une planification financière complète qui intègre des stratégies d'assurance adaptées à vos besoins spécifiques. Nous nous engageons à vous aider à protéger vos actifs, votre famille et votre avenir financier grâce à des solutions d'assurance bien conçues et à vous offrir la tranquillité d'esprit nécessaire pour atteindre vos objectifs financiers en toute confiance.</p>

                        <p> Avec notre approche intégrée, vous pouvez être assuré que nous mettrons en place un plan financier robuste qui prend en compte à la fois la croissance de votre patrimoine et la protection de ce qui compte le plus pour vous.</p>

                        <ul>
                            <li>Experts qualifiés</li>
                            <li>Services clients</li>
                            <li>Haute disponibilité</li>
                            <li>Respect des normes</li>
                        </ul>
                    

                        <hr/>

                        <h4>Prestations offertes</h4>
                        
                        <p></p>

                        <figure>
                            <img src="assets/img/about/prestation.jpg" alt="" />

                            <ul>
                                <li>Planification financière personnelle</li>
                                <li>Gestion de patrimoine</li>
                                <li>Analyse financière et conseils en investissement</li>
                                <li>Conseils en planification fiscale</li>
                                </ul>

                        </figure>

                        <hr/>

                        <h4>Questions Fréquentes</h4>

                
                    </div>

                    <div className="faq mt-40 mt-md-35 mt-sm-25 mt-xs-20" id="faq">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-1">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-1" aria-expanded="true" aria-controls="faq-1">
                                    <i className="icon-question-4"></i> Quels sont les types de services financiers que vous offrez ?
                                </button>
                            </h2>
                    
                            <div id="faq-1" className="accordion-collapse collapse show" aria-labelledby="h-faq-1" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Nous offrons une gamme complète de services financiers, y compris la gestion de patrimoine, la planification financière, les investissements,services bancaires.</p>
                                </div>
                            </div>
                        </div>
                    
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-2">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-2" aria-expanded="false" aria-controls="faq-2">
                                    <i className="icon-question-4"></i> Comment puis-je bénéficier de vos services financiers ?
                                </button>
                            </h2>
                    
                            <div id="faq-2" className="accordion-collapse collapse" aria-labelledby="h-faq-2" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Vous pouvez bénéficier de nos services financiers en planifiant une consultation avec l'un de nos experts financiers pour discuter de vos objectifs et de vos besoins financiers spécifiques.</p>
                                </div>
                            </div>
                        </div>
                    
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-3">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-3" aria-expanded="false" aria-controls="faq-3">
                                    <i className="icon-question-4"></i>Quelle est votre approche en matière de gestion d'investissement ?
                                </button>
                            </h2>
                    
                            <div id="faq-3" className="accordion-collapse collapse" aria-labelledby="h-faq-3" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Notre approche de gestion d'investissement est axée sur la diversification, la gestion active des risques et la recherche approfondie pour aider nos clients à atteindre leurs objectifs financiers à long terme.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4">
                    <div className="main-sidebar" data-sticky_column>
                        <div className="single-sidebar-widget mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20">
                            <h4 className="wid-title mb-30 mb-xs-20 color-d_black text-capitalize">Autres Services</h4>
                            
                            <div className="widget_categories">
                                <ul>
                                   <Link to={"/Informatique"}> <li><a href="">Informatique<i className="fas fa-long-arrow-alt-right"></i></a></li> </Link>
                                    <li><a href="#">Marché publique<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Conseil d'investissement<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Système d'archivage<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                </ul>                                
                            </div>
                        </div>

                        <div className="single-sidebar-widget have-any mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20"> 
                            
                            <div className="media">
                                <img src="assets/img/about/pi.jpg" alt="" />
                            </div>
                            
                            <div className="have-any__item text-center">
                                <h4 className="wid-title mb-20 mb-xs-15 color-white text-capitalize">Une question?</h4>

                                <a href="contact.html" className="theme-btn">Contactez-Nous <i className="fab fa-telegram-plane"></i></a>
                            </div>
                        </div>

                        <div className="single-sidebar-widget widget__tags mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20">
                            <h4 className="wid-title mb-30 mb-xs-20 color-d_black text-capitalize">Télécharger</h4>

                            <div className="download-service-doc">

                                <a href="#" className="theme-btn d-flex align-content-center flex-wrap justify-content-between btn-fdf">
                                    <div className="text">
                                        <img src="assets/img/icon/fdf-4.svg" alt="" /> Dépliant PDF
                                    </div>
                                    <i className="icon-download-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</>
       
    );
}