import { Link } from "react-router-dom";
import '../assets/styles/Banner.css';
import Header from './Header';
import Footer from './Footer';


export default function Autres(){

    return(
        <>
        <Header/>
        <Block1 />
        <Footer/>
       
        </>

    );

}

function Block1(){
    return(
<>
<section class="our-project pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-115 overflow-hidden">
        <br/>
        <div class="container">
        <h2 className="title color-secondary mb-20 mb-sm-15 mb-xs-10">Nos services</h2>

            <div class="row mb-minus-30">
                
                <div class="col-lg-4 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/finance.jpg"alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Economie et Finance</span>
                                <h5 class="title color-d_black">Finance et Assurance</h5>
                            </div>

                           <Link to={"/Finance"}> <a href="" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a></Link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/g.png"alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Marché publique</span>
                                <h5 class="title color-d_black">Accompagnement marchés publics et privées</h5>
                            </div>

                            <a href="our-project-details.html" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/stratégie.jpg" alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Conseil</span>
                                <h5 class="title color-d_black">Strategie</h5>
                            </div>

                            <a href="our-project-details.html" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/investir.jpg"alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Business</span>
                                <h5 class="title color-d_black">Investissement</h5>
                            </div>

                            <a href="our-project-details.html" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/it.jpg"alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Informatique</span>
                                <h5 class="title color-d_black">Transformation numérique</h5>
                            </div>

                            <Link to={"/Informatique"}>   <a href="" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a></Link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="our-project__item overflow-hidden mb-30">
                        <img src="assets/img/about/Archivage.png"alt="" />

                        <div class="content d-flex align-items-center justify-content-between">
                            <div class="text">
                                <span class="fw-500 color-primary d-block mb-10 text-uppercase">Informatique</span>
                                <h5 class="title color-d_black">Archivage Numérique</h5>
                            </div>

                            <a href="our-project-details.html" class="theme-btn"><img src="assets/img/icon/arrow-right-top.svg"alt="" /></a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-12">
                    <div class="page-nav-wrap text-center mt-50 mt-sm-40 mt-xs-35">
                        <ul>
                           <li class="arrow left"><a href="#"><i class="far fa-chevron-double-left"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li class="arrow right"><a href="#"><i class="far fa-chevron-double-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

</>
    );
}