import logo from '../assets/logo.svg';

import Header from './Header';
import Banner from './Banner';
import Competive from './Competive';
import Services from './Services';
import Footer from './Footer';
import Nous from './Nous';
import Archivage from './Archivage';
import Contact from './Contact';

function App(){
  return (
    <>
    <Header />
    <Banner />
    <Nous />
    <Archivage />
    <Services />
    <Contact/>
    <Footer/>
    
    </> 
    
  );
}

export default App;
