import { Link } from "react-router-dom";


import Header from './Header2';
import Footer from './Footer';
import Services from './Services';
import Contact from "./Contact";




export default function Marche(){

    return(
        <>

        <Header/>

       
        </>

    );

}

function Block1(){
    return(
<>

<section className="services-details pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-115 overflow-hidden">
        <div className="container">
            <div className="row" data-sticky_parent>
                <div className="col-xl-8" data-sticky_column>
                    <div className="media mb-40 mb-md-35 mb-sm-30 mb-xs-25">
                        <img src="assets/img/about/transformation.jpg" alt="" />
                    </div>

                    <div className="services-details__content">
                        <h2>Transformation Numérique</h2>

                        <p>Plongez dans une expérience de transformation digitale et numérique sans précédent avec notre équipe. En plus de repenser vos processus et stratégies, nous sommes des experts dans le développement d'applications sur mesure. Nous croyons fermement que l'innovation technologique ne devrait pas être limitée par des solutions génériques. C'est pourquoi nous nous efforçons de créer des applications uniques, parfaitement adaptées à vos besoins spécifiques.</p>

                        <p> En collaborant avec nous, vous bénéficierez d'une approche personnalisée qui libère le plein potentiel de la technologie pour votre entreprise. Ensemble, embrassons l'avenir numérique et bâtissons des solutions qui vous propulseront vers le succès.</p>

                        <ul>
                            <li>Instant Business Growth</li>
                            <li>Easy Customer Service</li>
                            <li>24/7 Quality Service</li>
                            <li>Quality Cost Service</li>
                        </ul>
                        
                        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae.</p>

                        <hr/>

                        <h4>Prestations offertes</h4>
                        
                        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p>

                        <figure>
                            <img src="assets/img/about/prestation.jpg" alt="" />

                            <ul>
                                <li>Will give you a complete account</li>
                                <li>Easy Customer Service</li>
                                <li>Excepteur sint occaecat cupidatat non.</li>
                                <li>The master-builder of human happiness</li>
                                <li>Duis aute irure dolor in reprehenderit</li>
                                <li>complete account of the system</li>
                            </ul>
                        </figure>

                        <hr/>

                        <h4>Questions Fréquentes</h4>

                        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p>
                    </div>

                    <div className="faq mt-40 mt-md-35 mt-sm-25 mt-xs-20" id="faq">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-1">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-1" aria-expanded="true" aria-controls="faq-1">
                                    <i className="icon-question-4"></i> What should i included my personal details? 
                                </button>
                            </h2>
                    
                            <div id="faq-1" className="accordion-collapse collapse show" aria-labelledby="h-faq-1" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                </div>
                            </div>
                        </div>
                    
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-2">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-2" aria-expanded="false" aria-controls="faq-2">
                                    <i className="icon-question-4"></i> How do consultants solve problem?
                                </button>
                            </h2>
                    
                            <div id="faq-2" className="accordion-collapse collapse" aria-labelledby="h-faq-2" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                </div>
                            </div>
                        </div>
                    
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="h-faq-3">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq-3" aria-expanded="false" aria-controls="faq-3">
                                    <i className="icon-question-4"></i> We can help your business to grow?
                                </button>
                            </h2>
                    
                            <div id="faq-3" className="accordion-collapse collapse" aria-labelledby="h-faq-3" data-bs-parent="#faq">
                                <div className="accordion-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4">
                    <div className="main-sidebar" data-sticky_column>
                        <div className="single-sidebar-widget mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20">
                            <h4 className="wid-title mb-30 mb-xs-20 color-d_black text-capitalize">Autres Services</h4>
                            
                            <div className="widget_categories">
                                <ul>
                                    <li><a href="#">Finance<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Assurance<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Marché publique<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Conseil d'investissement<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    <li><a href="#">Système d'archivage<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                </ul>                                
                            </div>
                        </div>

                        <div className="single-sidebar-widget have-any mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20"> 
                            
                            <div className="media">
                                <img src="assets/img/services-details/have-any.png" alt="" />
                            </div>
                            
                            <div className="have-any__item text-center">
                                <h4 className="wid-title mb-20 mb-xs-15 color-white text-capitalize">Une question?</h4>

                                <a href="contact.html" className="theme-btn">Contactez-Nous <i className="fab fa-telegram-plane"></i></a>
                            </div>
                        </div>

                        <div className="single-sidebar-widget widget__tags mb-40 pt-30 pr-30 pb-40 pl-30 pl-xs-20 pr-xs-20">
                            <h4 className="wid-title mb-30 mb-xs-20 color-d_black text-capitalize">Download</h4>

                            <div className="download-service-doc">
                                <a href="#" className="theme-btn d-flex align-content-center flex-wrap justify-content-between">
                                    <div className="text">
                                        <img src="assets/img/icon/fdf-1.svg" alt="" /> Documentation
                                    </div>
                                    
                                    <i className="icon-download-1"></i>
                                </a>

                                <a href="#" className="theme-btn d-flex align-content-center flex-wrap justify-content-between btn-fdf">
                                    <div className="text">
                                        <img src="assets/img/icon/fdf-4.svg" alt="" /> Download PDF
                                    </div>
                                    <i className="icon-download-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</>
       
    );
}