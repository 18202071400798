import { Link } from "react-router-dom";
export default function Services(){

    return(
        <>
            <section className="" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="employee-friendly__content mb-65 mb-md-50 mb-sm-40 mb-xs-30 text-center">

                    </div>
                </div>
            </div>
            <div className="about-us__content  mb-lg-60 mb-md-50 mb-sm-40 mb-xs-30">
                        <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" />SERVICES</span>
                        <h2 className="title color-secondary mb-20 mb-sm-15 mb-xs-10">Nos prestations</h2>

                      
                    </div>
            <div className="row mb-minus-30">
                <div className="col-lg-4 col-md-6">
                    <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                        <div className="top d-flex align-items-center">
                            <div className="icon color-white text-center bg-yellow">
                                <i className="fal fa-analytics"></i>
                            </div>

                            <h4 className="title color-secondary"><a href="services.html">Market intelligence</a></h4>
                        </div>

                        <div className="bottom">    
                            <div className="media overflow-hidden">
                                <img src="assets/img/services/finance.jpg" className="img-fluid" alt="" />
                            </div>
                            
                            <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                <p>Nous apportons aux entreprises la clé pour comprendre le tissu économique de la République Démocratique du Congo.</p>
                            </div>
                            
                            <Link to={"/Finance"}> <a href="" className="theme-btn text-center fw-600 btn-yellow">Découvrir<i className="fas fa-long-arrow-alt-right"></i></a></Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                        <div className="top d-flex align-items-center">
                            <div className="icon color-white text-center bg-yellow">
                                <i className="icon-planning"></i>
                            </div>

                            <h4 className="title color-secondary"><a href="services.html">Accompagnement marchés publics et privées</a></h4>
                        </div>

                        <div className="bottom">    
                            <div className="media overflow-hidden">
                                <img src="assets/img/services/conseil.jpg" className="img-fluid" alt="" />
                            </div>
                            
                            <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                <p>Nous accompagnons les sociétés étrangères à soumissionner aux appels d’offres locales et nous identifions pour ces dernières les partenaires adéquat.</p>
                            </div>
                            
                         <Link to={"/Marche"}><a href="" className="theme-btn text-center fw-600 btn-yellow">Découvrir <i className="fas fa-long-arrow-alt-right"></i></a></Link>   
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="similar-work__item mb-30 d-flex justify-content-between flex-column">
                        <div className="top d-flex align-items-center">
                            <div className="icon color-white text-center bg-yellow">
                                <i className="icon-outline"></i>
                            </div>

                            <h4 className="title color-secondary"><a href="services.html">Informatique</a></h4>
                        </div>

                        <div className="bottom">    
                            <div className="media overflow-hidden">
                                <img src="assets/img/services/it.jpg" className="img-fluid" alt="" />
                            </div>
                            
                            <div className="text pt-30 pr-30 pb-30 pl-30 pt-sm-20 pt-xs-15 pr-sm-20 pr-xs-15 pb-sm-20 pb-xs-15 pl-sm-20 pl-xs-15 font-la">
                                <p>Accompagnement de bout en bout pour une transformation digitale réussie, alignée sur vos objectifs stratégiques et opérationnels.</p>
                            </div>
                            
                          <Link to={"/Informatique"}><a href="" className="theme-btn text-center fw-600 btn-yellow">Découvrir<i className="fas fa-long-arrow-alt-right"></i></a></Link> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="theme-btn-wrapper mt-50 mt-md-40 mt-sm-30 mt-xs-25 text-center">
                       <Link to={"/Services"}><a href="" className="theme-btn btn-transparent fw-600"><i className="far fa-sync-alt"></i> Encore plus Services</a></Link>
                        <br/>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
        
    );

}

