
import Header from './Header2';
import Footer from './Footer';
import Services from './Services';
import Contact from "./Contact";

;
export default function He(){

    return(
        <>
        <Header/>
        <Block1 />
        <Services/>
        <Footer/>
       
        </>

    );

}

function Block1(){
    return(
<>
<section class="our-company  pt-xs-80 pb-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6"> 
                    <div class="our-company__meida">
                        <img src="assets/img/about/g.png" alt="" class="img-fluid" />

                        <div class="years-experience overflow-hidden mt-20 mt-sm-10 mt-xs-10 text-center">
                            <div class="number mb-5 color-white">
                                <span class="counter">70</span><sup>+</sup>
                            </div>

                            <h5 class="title color-white">Experts dans notre équipe</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6"> 
                    <div class="our-company__meida border-radius">
                        <img src="assets/img/about/pi.jpg" alt="" class="img-fluid" />

                        <div class="horizental-bar"></div>
                    </div>
                </div>
                <br/>

                <div class="col-lg-6">
                    <div class="our-company__content mt-md-50 mt-sm-40 mt-xs-35">
                        <br/>
                        <br/>
                        <span class="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-20 d-block"><img src="assets/img/team-details/badge-line.svg" class="img-fluid mr-10" alt="" /> NOTRE ORGANISATION</span>
                        <h2 class="title color-d_black mb-20 mb-sm-15 mb-xs-10">Notre société fournit des solutions de haute qualité</h2>

                       
                        <div class="client-feedback d-flex flex-column flex-sm-row">
                            <div class="client-feedback__item text-center">
                                <div class="client-feedback__item-header">
                                    <span class="color-primary font-la fw-600 text-uppercase">Projets</span>
                                </div>

                                <div class="client-feedback__item-body">
                                    <div class="number mb-10 mb-xs-5 color-d_black fw-600">+<span class="counter">135</span></div>
                      
                                    <div class="starts">
                                        <ul>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="client-feedback__item text-center">
                                <div class="client-feedback__item-header">
                                    <span class="color-primary font-la fw-600 text-uppercase">Clients</span>
                                </div>

                                <div class="client-feedback__item-body">
                                    <div class="number mb-10 mb-xs-5 color-d_black fw-600">+<span class="counter">100</span></div>
    
                                    <div class="starts">
                                        <ul>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                            <li><span></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section >
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="company-skill__content"> 
                        <span class="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20"><img src="assets/img/team-details/badge-line.svg" class="img-fluid mr-10" alt="" />Technologie de l'information</span>
                        <h2 class="title color-pd_black mb-25 mb-xs-10 mb-sm-15">INFORMATIQUE<span></span></h2>

                        <div class="description font-la">
                            <p>Nous sommes une entreprise spécialisée dans les solutions aux entreprises. Nous faisons de vos problèmes et projets, les nôtres et apportons la solution qui vous convient.
Nous fournissons des solutions personnalisées dans divers domaines liés au développement des entreprises, principalement sur le plan informatique dans la dématérialisation des documents à savoir la numérisation et l'archivage.</p>
                        </div>


                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center">

                        <div class="company-skill__media">
                            <img src="assets/img/about/secteur1.jpg" alt="" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
    </section>
    <section >
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="company-skill__content"> 
                        <span class="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20"><img src="assets/img/team-details/badge-line.svg" class="img-fluid mr-10" alt="" />Plannifications</span>
                        <h2 class="title color-pd_black mb-25 mb-xs-10 mb-sm-15">Market intelligence<span></span></h2>

                        <div class="description font-la">
                         <p>Nous apportons aux entreprises la clé pour comprendre le tissu économique de la République Démocratique du Congo. Nous menons pour eux les enquêtes de terrain qui leurs permettra de se développer. Notre domaine de collaboration dans ce domaine est très large, à savoir (mines, énergie, agroalimentaire etc …)
-   Mines 
-	Energie
-	Agroalimentaire
-	Recherche des partenaires locaux


</p>
                        </div>


                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center">

                        <div class="company-skill__media">
                            <img src="assets/img/about/secteur2.jpg" alt="" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
    </section>
    <section >
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="company-skill__content"> 
                        <span class="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20"><img src="assets/img/team-details/badge-line.svg" class="img-fluid mr-10" alt="" />Marchés publics et privée</span>
                        <h2 class="title color-pd_black mb-25 mb-xs-10 mb-sm-15">Accompagnement marchés publics et privées<span></span></h2>

                        <div class="description font-la">
                            <p>Nous accompagnons les sociétés étrangères à soumissionner aux appels d’offres locales et nous identifions pour ces dernières les partenaires adéquat.</p>
                        </div>


                    </div>
                </div>

                <div class="col-xl-6">
                    <div class="company-skill__media-wrapper d-flex flex-column mt-lg-60 mt-md-50 mt-sm-45 mt-xs-40 align-items-center">

                        <div class="company-skill__media">
                            <img src="assets/img/about/secteur3.jpg" alt="" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
    </section>
    


    <div class="counter-area pb-xs-80 pb-sm-120 pb-md-120 pb-lg-120 pb-xl-140 pb-170 overflow-hidden">
        <div class="container">
            <div class="row mb-minus-30">
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="counter-area__item d-flex align-items-center">
                        <div class="icon color-primary">
                            <i class="icon-process-1"></i>
                        </div>

                        <div class="text text-center">
                            <div class="number fw-600 color-primary"><span class="counter">135</span>+</div>
                            <div class="description font-la">Projets</div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="counter-area__item d-flex align-items-center">
                        <div class="icon color-primary">
                            <i class="icon-support-2"></i>
                        </div>

                        <div class="text text-center">
                            <div class="number fw-600 color-primary"><span class="counter">70</span>+</div>
                            <div class="description font-la">Consultants</div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="counter-area__item d-flex align-items-center">
                        <div class="icon color-primary">
                            <i class="icon-coffee-2"></i>
                        </div>

                        <div class="text text-center">
                            <div class="number fw-600 color-primary"><span class="counter">50</span>+</div>
                            <div class="description font-la">Assistances</div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="counter-area__item d-flex align-items-center">
                        <div class="icon color-primary">
                            <i class="icon-teamwork-1"></i>
                        </div>

                        <div class="text text-center">
                            <div class="number fw-600 color-primary"><span class="counter">100</span>+</div>
                            <div class="description font-la">Clients</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Contact/>
    </>
    );
}