import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import { BrowserRouter as Router , Routes, Route} from 'react-router-dom' ;
import App from './components/App';
import He from './components/he';
import Nous from './components/Nous';
import It from './components/It';
import Finance from './components/Finance';
import Marche from './components/Marche';
import Autres from './components/Autres';
import reportWebVitals from './reportWebVitals';
import { Link } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>       
  <Routes> 
  <Route path='/' element={<App/>} />
  <Route path='/Helvetica-infos' element={<He/>} />
  <Route path='/apropos' element={<Nous/>} />
  <Route path='/Informatique' element={<It/>} />
  <Route path='/Services' element={<Autres/>} />
  <Route path='/Finance' element={<Finance/>} />
  <Route path='/Marche' element={<Marche/>} />

  </Routes>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
