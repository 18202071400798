import { Link } from "react-router-dom";
import '../assets/styles/Banner.css';
export default function Banner(){

    return(
        <>
        <Block1 />
       
        </>

    );

}

function Block1(){
    return(

        <section className="banner overflow-hidden"  >
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="banner__content">

                        <h1 className="title color-d_black mb-sm-10 mb-xs-5 mb-15">Nous trouvons des solutions  <span> à vos problèmes.</span></h1>
                     
                    
                  
                        <Link to={"/Helvetica-infos"}>   <a href="" className="theme-btn">Nous découvrir <i className="far fa-chevron-double-right"></i></a></Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}