export default function Footer(){

    return(
        <>
        <Block1 />
       
        </>

    );

}

function Block1(){
    return(

        <>
          <footer className="footer-1 overflow-hidden">
        <div className="">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="">
                            <a href="index.html" className="d-block mb-20">
                           
                                  HELVETICA RESSOURCES SARL
                            </a>
                            
                            <div className="description font-la color-white">
                                <p>Chez Helvetica Ressources, nous combinons l'excellence en informatique, finance, business et assurance pour propulser votre entreprise vers le succès.</p>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <br/>

                    <div className="col-lg-6">
                        <div className="single-footer-wid newsletter_widget">
                            <h6 className="title d-flex align-items-center color-white mb-30"><img src="assets/img/icon/notification.svg" alt=""/>Inscriver vous pour rester informer.</h6>
                            
                            <div className="newsletter_box">
                                <form action="mail.php" method="POST">
                                    <input type="email" name="email" placeholder="Entrer votre addresse Email" required /> 
                                    <button className="theme-btn" type="submit">S'inscrire<i className="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>   
        </div>
        

       
        <div className="footer-bottom overflow-hidden">
            <div className="container">
                <div className="footer-bottom-content d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="coppyright text-center text-md-start">
                        &copy; 2024 <a href="index.html">HELVETICA RESSOURCES SARL</a>
                    </div>

                    <div className="footer-bottom-list last_no_bullet">
                        <ul>
                            <li><a href="">Termes & Conditions</a></li>
                            <li><a href="">Politiques de confidentialité</a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
        </>
       
    );
}