import { Link } from "react-router-dom";
export default function Nous(){

    return(
        <>
        <Block1 />
       
        </>

    );

}

function Block1(){
    return(

        <>
        <section className="about-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden" id="apropos">
        <div className="container">
            <div className="row">
                <div className="col-xl-6">
                    <div className="about-us__content  mb-lg-60 mb-md-50 mb-sm-40 mb-xs-30">
                        <span className="sub-title fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" />À PROPOS</span>
                        <h2 className="title color-secondary mb-20 mb-sm-15 mb-xs-10">Votre partenaire de confiance pour des solutions innovantes</h2>

                        <div className="description font-la mb-50 mb-sm-40 mb-xs-30">
                            <p>Helvetica Ressources se distingue par son modèle d’affaire. La société s’appuit sur son réseau de partenaires dans le monde. Ce réseau lui permet d’identifier le partenaire disposant de la solution qui vous convient.</p>
                        </div>

      
                       <Link to={"/Helvetica-infos"}><a href="" className="theme-btn  btn-yellow-transparent fw-600">En savoir plus<i className="fas fa-long-arrow-alt-right"></i></a></Link>
                    </div>
                </div>

                <div className="col-xl-6">
                    <div className="about-us__media d-flex align-content-center justify-content-center align-items-center">
                        <div className="media">
                            <img src="assets/img/home-2/about-media.png" className="img-fluid" alt="" />
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    );
}