export default function Archivage(){
    return(
        <>
        <Block1 />
       
        </>

    );

}

function Block1(){
    return(
        <>
            <section id="solution">
        <div className="container">
            <div className="row">
                <div className="col-xl-6">
                    <div className="why-choose__media-wrapper d-flex flex-column">
                        <div className="gallery-bar bg-yellow"></div>

                        <div className="why-choose__media">
                            <img src="assets/img/banner/archivage.png" alt="" className="img-fluid" />
                        </div>

                        <div className="global-country text-center bg-yellow">
                            <div className="number mb-5 color-white"><span className="counter">30</span>+</div>
                            <h6 className="title color-white font-la">Fonctionalités Disponibles</h6>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6">
                    <div className="why-choose__content mt-lg-60 mt-md-50 mt-sm-40 mt-xs-35">
                        <div className="why-choose__text mb-40 mb-md-35 mb-sm-30 mb-xs-30">
                            <span className="sub-title d-block fw-500 color-yellow text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-lg-20 mb-25"><img src="assets/img/team-details/badge-line-yellow.svg" className="img-fluid mr-10" alt="" />NOTRE SOLUTION PHARE</span>
                            <h2 className="title color-pd_black">Solution gestion et archivage de document.</h2>
                            
                            <div className="description font-la mt-20 mt-sm-15 mt-xs-10">
                                <p>Optimisez la productivité de votre entreprise avec notre solution de gestion de documents et d'archivage. Bénéficiez de fonctionnalités avancées telles que la recherche intelligente, la gestion des versions, le partage sécurisé et la conformité réglementaire, pour une collaboration transparente et une efficacité accrue au quotidien.</p>
                            </div>
                        </div>

                        <div className="why-choose__item-wrapper d-grid justify-content-between"> 
                            <div className="why-choose__item">
                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                    <i className="icon-consultation"></i>
                                </div>
                            
                                <h5 className="title color-secondary fw-500 mb-10">Gestion des droits d'accès</h5>
                                
                                <div className="description font-la">
                                    <p>Permettant de collaborer sur des documents tout en contrôlant l'accès et les autorisations.</p>
                                </div>
                            </div> 
                            
                            <div className="why-choose__item">
                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                    <i className="icon-lawyer"></i>
                                </div>
                            
                                <h5 className="title color-secondary fw-500 mb-10">Conformité réglementaire</h5>
                                
                                <div className="description font-la">
                                    <p> Assurant que les documents sont archivés et gérés en respectant les normes et réglementations en vigueur.</p>
                                </div>
                            </div> 

                            <div className="why-choose__item">
                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                    <i className="icon-financial"></i>
                                </div>
                            
                                <h5 className="title color-secondary fw-500 mb-10">Gestion des versions</h5>
                                
                                <div className="description font-la">
                                    <p>Assurant un suivi précis des modifications apportées aux documents au fil du temps.</p>
                                </div>
                            </div>
                            
                            <div className="why-choose__item">
                                <div className="icon mb-15 mb-md-10 mb-xs-5 color-yellow">
                                    <i className="icon-management"></i>
                                </div>
                            
                                <h5 className="title color-d_black secondary-500 mb-10"> Recherche avancée</h5>
                                
                                <div className="description font-la">
                                    <p>Permettant de trouver rapidement des documents par mots-clés, dates, ou autres critères pertinents.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
       
    );
}